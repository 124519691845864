/** Cosmos 954: Basketball **/

import React from "react";
import { gsap } from "gsap"
import FullscreenVideo from "../../components/media/fullscreen-video"
import { preloadImages } from "../../helpers"
import PrevNext from "../../components/navigation/prevnext"
import SEO from "../../components/seo"
import { showText, hideText, positionText, resetAnimationDelay } from "../../helpers"


let globalState, data, preloadData, voTimeout;

class CosmosPage2 extends React.Component {

  constructor(props) {
    super(props);

    //update global state and assets data
    globalState = this.props.location.state;
    data = globalState.assets[2];
    preloadData = globalState.assets[3];

    //refs
    this.bgVideoRef = this.fullscreenBg = null;
  }

  state = {
    textVisible: false,
  }


  componentDidMount = () => {

    setTimeout(() => {
      this.bgVideoRef.playVideo();
    }, globalState.transitionDuration);

    //position text
    positionText(this.refs.captionRef, "right", "top", globalState.textPosition.rightTop);
    

    //show text
    gsap.to(this.refs.captionRef, 0.15, { autoAlpha: 1.0, delay: 2 });

    //add base track
    globalState.baseAudioRef.updateTrack(1, globalState.cdnUrl + data.soundfile1); //sports loop
    
    voTimeout = setTimeout(() => {
      //add vo track
      globalState.baseAudioRef.updateTrack(3, globalState.cdnUrl + data.soundfile3); //VO
      //update volume base track
      //globalState.baseAudioRef.updateVolume(1, 70);
    }, 1500);

    //Preload assets from next page
    preloadImages([
      globalState.cdnUrl + preloadData.background,
      globalState.cdnUrl + preloadData.text1,
      globalState.cdnUrl + preloadData.toothpick,
      globalState.cdnUrl + preloadData.hand1,
      globalState.cdnUrl + preloadData.hand2,
      globalState.cdnUrl + preloadData.hand3,
      globalState.cdnUrl + preloadData.hand4,
      globalState.cdnUrl + preloadData.hand5,
      globalState.cdnUrl + preloadData.hand6,
      globalState.cdnUrl + preloadData.hand7,
      globalState.cdnUrl + preloadData.hand8,
    ], this.preloadCallback);


  }

  preloadCallback = () => {
    this.refs.prevNextRef.showPrevNextBtns(globalState.showPrevNextDelay);
  }

  componentWillUnmount() {
    this.bgVideoRef.pauseVideo();

    //Kill any tweens
    gsap.killTweensOf(this.refs.captionRef);

    //Clear vo track timeout
    clearTimeout(voTimeout);


  }

  toggleTextDisplay = (event) => {

    //toggle text visibility
    this.setState({ textVisible: !this.state.textVisible });

    //reset the animation delay
    resetAnimationDelay();

    if (this.state.textVisible) {
      //show text
      showText(this.refs.captionRef, "right", "top", globalState.textPosition.rightTop);


    } else {
      //hide text
      hideText(this.refs.captionRef, "right");
    }
  }

  isTransitioning = () => {
    this.bgVideoRef.pauseVideo();
  }



  render() {

    return (

      <>

        <link rel="prefetch" href="/cosmos-954/3" />

        <PrevNext globalState={globalState} ref="prevNextRef"
          nextLocation="/cosmos-954/3" prevLocation="/cosmos-954/1"
          isTransitioning={this.isTransitioning} />
        {data && (
        <div className="fullpage-wrapper">
          <SEO title="Pine Point - Cosmos 954" />


          <div className="text-wrapper">
            <div className="text text--right-top" ref="captionRef">
              <input type="image" src={globalState.cdnUrl + data.caption} style={{ marginRight: "30px", marginTop: "0px" }} onClick={this.toggleTextDisplay} alt="Matonabbee Chiefs Game" />
            </div>
          </div>

          {data.bgVideo ?
            <FullscreenVideo file={globalState.videoUrl + data.bgVideo}
              poster={globalState.cdnUrl + data.bgVideoPoster} autoPlay={false} loop={true} ref={div => this.bgVideoRef = div} fullscreenClass={'fullscreen-video'} globalState={globalState} /> : ''
          }
        </div>
        )}
      </>
    );
  }


};

export default CosmosPage2
